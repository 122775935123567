<template>
  <div class="app flex-row align-items-center">
    <div class="container sign-in-container">
      <div class="my-5 px-4 text-center">
        <b-img
          src="/CU2_Logo.png"
          fluid
          alt="CU2.0 Logo"
          class="logo mx-auto"
          v-if="isPartnerSite"
        />
        <b-img
          src="/logo.png"
          fluid
          alt="FINavigator Logo"
          class="logo mx-auto"
          v-else
        />
      </div>
      <b-row class="justify-content-center row-eq-height">
        <b-col md="8" class="pr-1 pl-1">
          <b-card no-body>
            <b-card-body class="p-4">
              <h2 class="mb-0">User Login</h2>
              <p class="text-muted">Sign In to your account</p>
              <b-input-group class="mb-4 pt-2">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-envelope"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.user.email.$error,
                    'is-valid': !$v.user.email.$invalid
                  }"
                  placeholder="Email"
                  v-model="user.email"
                  @blur="$v.user.email.$touch()"
                />
                <b-form-invalid-feedback
                  v-if="!$v.user.email.required"
                  class="within-input"
                  >Email can't be blank</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="!$v.user.email.email"
                  class="within-input"
                >
                  Please provide a valid email address</b-form-invalid-feedback
                >
              </b-input-group>
              <b-input-group class="mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-key"></i
                  ></span>
                </div>
                <input
                  type="password"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.user.password.$error,
                    'is-valid': !$v.user.password.$invalid
                  }"
                  placeholder="Password"
                  v-model="user.password"
                  @keyup.enter="loginSubmit()"
                  @blur="$v.user.password.$touch()"
                />
                <b-form-invalid-feedback
                  v-if="!$v.user.password.required"
                  class="within-input"
                  >Password can't be blank</b-form-invalid-feedback
                >
              </b-input-group>
              <b-row class="mb-3">
                <b-col cols="12" class="text-center">
                  <b-button
                    variant="primary"
                    class="px-4 login-btn fw-btn"
                    :disabled="$v.user.$invalid || submitting"
                    @click="loginSubmit"
                  >
                    {{ submitText() }}</b-button
                  >
                </b-col>
              </b-row>
              <div class="row">
                <div class="col-sm-6 text-left">
                  <div class="py-2">
                    Don't have an account?
                    <router-link
                      to="/sign_up"
                      tag="span"
                      class="look-as-a-tag cursor-pointer"
                      >Request Access</router-link
                    >
                  </div>
                </div>
                <b-col cols="6" class="text-right">
                  <b-button variant="link" class="px-0" @click="resetForgot"
                    >Forgot password?</b-button
                  >
                </b-col>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <ForgotPasswordModal
      :email="user.email"
      :showForgotPasswordModal="showForgotPasswordModal"
      @closeForgotPasswordModal="closeForgotPasswordModal"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store/store'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import ForgotPasswordModal from '@/components/ForgotPasswordModal'

export default {
  name: 'Login',
  components: {
    ForgotPasswordModal
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.currentUserEmail) {
        vm.$router.push('/')
      } else {
        next()
      }
    })
  },
  beforeCreate() {
    document.title = 'FI Navigator'
  },
  created() {
    if (this.redirectUrl && !this.$route.params.silent) {
      Vue.toasted.show('Please login to access the requested page', {
        icon: 'key',
        type: 'error'
      })
    }
    if (this.$route.query.activation === 'success') {
      Vue.toasted.show(
        'Email successfully activated, please sign in to continue.',
        { icon: 'key', type: 'success' }
      )
    }
    if (this.$route.query.activation === 'request_submitted') {
      Vue.toasted.show(
        'Access Request successfully submitted, verify email to continue.',
        { icon: 'key', type: 'success' }
      )
    }
  },
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      submitting: false,
      showForgotPasswordModal: false
    }
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters(['redirectUrl']),

    isPartnerSite() {
      return window.location.host === process.env.VUE_APP_ETHOS_URL_NAME
    }
  },
  methods: {
    ...mapActions('Authentication', ['logIn']),
    loginSubmit: function() {
      this.submitting = true
      const downloadUrl = this.$route.query.url
      this.logIn({ user: this.user, skipRedirect: false }).then(() => {
        if (window.location.host === process.env.VUE_APP_ETHOS_URL_NAME) {
          store.commit('setPartnerSite', 'cu2')
        } else {
          store.commit('setPartnerSite', null)
        }
        if (downloadUrl !== undefined) {
          window.open(downloadUrl)
        }
        this.submitting = false
      })
    },
    submitText: function() {
      return this.submitting ? 'Logging...' : 'Login'
    },
    resetForgot() {
      this.showForgotPasswordModal = true
    },
    closeForgotPasswordModal: function() {
      this.showForgotPasswordModal = false
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    },
    forgotEmail: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-msg {
  margin-bottom: 0;
}

.login-btn {
  margin-top: 0.3rem;
}

.within-input {
  position: absolute;
  margin-top: 2.3rem;
}

.logo {
  max-height: 100px;
}

.sign-in-container {
  margin-top: -10rem;
}
</style>
